<template>
  <div class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
    <main>
      <div class="main_content align-items-center">
        <div class="row justify-content-between">
          <div class="col-md-6">
            <div class="heading">
              <h2>Audit Log Viewer</h2>
              <h3>PBE Management Dashboard</h3>
            </div>
          </div>
          <div class="col-md-6 text-sm-end mt-md-0 mt-2">
            <a class="btn btn_primary ml_24 d-none" href="#"
              >Export Audit Log</a
            >
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="table_card">
              <div id="datatable_wrapper" class="dataTables_wrapper no-footer">
                <div class="dataTables_length" id="datatable_length">
                  <label
                    >Show
                    <select
                      name="datatable_length"
                      aria-controls="datatable"
                      class=""
                      v-model.number="itemsPerPage"
                      @change="getProductsBysearch"
                    >
                      <option>10</option>
                      <option>25</option>
                      <option>50</option>
                      <option>100</option>
                      <option>500</option>
                    </select>
                    entries</label
                  >
                </div>
                <div id="datatable_filter" class="dataTables_filter">
                  <label
                    ><input
                      type="search"
                      class=""
                      placeholder="Search"
                      aria-controls="datatable"
                      v-model="listConfig.search_string" 
                      v-on:keyup.enter="getProductsBysearch"
                  />
                  <a v-if="listConfig.search_string" href="javascript:void(0)" @click="getProductsBysearch" class="search_icon cntr_search">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#00" class="bi bi-search" viewBox="0 0 16 16"><path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/></svg>
                    </a>
                    <a href="javascript:void(0)" v-if="listConfig.search_string" @click="listConfig.search_string = null; getProductsBysearch()"  class="crossicon cntr_cross">
                        X
                    </a>
                  </label>
                </div>
              </div>
              <div class="table-responsive table_audit_log">
                <table class="table table-bordered table dataTable no-footer">
                  <thead>
                    <tr>
                      <!-- <th class="sort">
                        <SortIcons
                          :lable="'Event ID'"
                          :listConfig="listConfig"
                          :sortBy="'id'"
                          @sorting="sorting($event)"
                        />
                      </th> -->
                      <th class="sort">
                        <SortIcons
                          :lable="'Date'"
                          :listConfig="listConfig"
                          :sortBy="'created_at'"
                          @sorting="sorting($event)"
                        />
                      </th>
                      <th>User</th>
                      <th>Source IP</th>
                      <th>Area</th>
                      <th>Event Type</th>
                      <th>Event Message</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(audit, indx) in audits" :key="indx">
                      <!-- <td>{{ audit.id }}</td> -->
                      <td>
                        <p class="audit_date_date">
                          {{ audit.date }}
                        </p>
                      </td>
                      <td>
                        <div class="d-flex audit_user">
                          <div class="flex-shrink-0">
                            <img :src="audit.avatar" alt="img" />
                          </div>
                          <div class="flex-grow-1 ms-3">
                            {{ audit.user_name }}
                            <p>{{ audit.role_name }}</p>
                          </div>
                        </div>
                      </td>
                      <td>
                        {{ audit.source_ip }}
                      </td>
                      <td>
                        {{ audit.area }}
                      </td>
                      <td>
                        <div class="d-flex align-items-center">
                          <div class="flex-grow-1">
                            <div class="message_box">
                              <p>{{ audit.event }}</p>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex align-items-center">
                          <div class="flex-grow-1">
                            <div class="message_box">
                              <p v-if="audit.event_message.length > 100" v-tooltip="audit.event_message" :v-html="audit.event_message">{{ audit.event_message }}</p> 
                              <p  v-else :v-html="audit.event_message">{{ audit.event_message }}</p>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="data_length == 0">
                      <td colspan="7" class="text-center">No Records found</td>
                    </tr>
                  </tbody>
                  <tfoot></tfoot>
                </table>
              </div>
              <p class="float-lg-start text-sm-center">{{ positionText }}</p>
              <VPagination
                v-if="data_length"
                class="dataTables_paginate"
                :totalPages="pages.length"
                :perPage="itemsPerPage"
                :currentPage.sync="currentPage"
                @pagechanged="onPageChange"
              />
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import "vue-select/dist/vue-select.css";
import VPagination from "@/components/VPagination";
import SortIcons from "@/components/SortingIcons";
import commonFunction  from '../../mixin/commonFunction'
export default {
  name: "audit_log_viewer",
  mixins: [commonFunction],
  data() {
    return {
      audits: [],
      isLoader: false,
      currentPage: 1,
      itemsPerPage: 10,
      listConfig: {
        offset: (this.currentPage - 1) * this.itemsPerPage,
        limit: this.itemsPerPage,
        sortBy: "created_at",
        order: "DESC",
        search_string: null,
      },
      data_length: 0,
    };
  },
  components: {
    VPagination,
    SortIcons,
  },
  computed: {
    positionText: function () {
      var endIndex = this.currentPage * this.itemsPerPage,
        startIndex = (this.currentPage - 1) * this.itemsPerPage + 1;
      if (this.data_length == 0) {
        startIndex = 0;
      }
      return (
        "Showing " +
        startIndex +
        " to " +
        (endIndex > this.data_length ? this.data_length : endIndex) +
        " of " +
        this.data_length
      );
    },
    pages() {
      if (this.data_length <= this.itemsPerPage) {
        return [1];
      }
      let pages_array = [
        ...Array(Math.ceil(this.data_length / this.itemsPerPage)).keys(),
      ].map((e) => e + 1);
      return pages_array;
    },
  },
  watch: {
    currentPage(newValue) {
      this.listConfig.offset = (newValue - 1) * this.itemsPerPage;
      this.getAuditLog();
    },
  },
  mounted() {
    document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title;
    this.listConfig = {
      offset: (this.currentPage - 1) * this.itemsPerPage,
      limit: this.itemsPerPage,
      sortBy: "created_at",
      order: "DESC",
      search_string: null,
    };
    if (localStorage.getItem("userToken")) {
      var _this = this;
      _this.getAuditLog();
    } else {
      this.router.push({ name: "login" });
    }
  },
  methods: {
    onPageChange(page) {
      this.currentPage = page;
    },
    sorting($event) {
      this.listConfig.sortBy == $event.sortBy;
      this.listConfig.order == $event.order;
      this.getAuditLog();
    },
    getProductsBysearch() {
      this.currentPage = 1;
      this.listConfig.limit = this.itemsPerPage;
      this.getAuditLog();
    },
    
    getAuditLog() {
      var _this = this;
      if (localStorage.getItem("userToken")) {
        _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
        _this.isLoader = true;
        let config = {
          method: "post",
          url: process.env.VUE_APP_API_URL + "/api/audit-logs-pagination",
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
          },
          data: {request_data : this.encodeAPIRequest(_this.listConfig)},
        };

        _this
          .axios(config)
          .then(({ data }) => {
            var decodedJson = _this.decodeAPIResponse(data.data);
            _this.audits = decodedJson.audits;
            _this.data_length = decodedJson.counts;
            _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          })
          .catch(({ response }) => {
            _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
            if(response.status==500){
              _this.$toast.error(response.data.error, {
                position: "top-right",
                duration: 5000,
              });
            }  
            if (response.data.type == false) {
            _this.$router.push({ name: "login" });
            }         
          });
      } else {
        _this.tokenExpired();
      }
    },
  },
};
</script>